export const getBrowserName = (userAgent: string) => {
  const test = (regexp: RegExp) => {
    return regexp.test(userAgent)
  }

  if (test(/edg/i)) {
    return 'Edge'
  }

  if (test(/chrome|chromium|crios/i)) {
    return 'Chrome'
  }

  if (test(/firefox|fxios/i)) {
    return 'Firefox'
  }

  if (test(/safari/i)) {
    return 'Safari'
  }

  return undefined
}
