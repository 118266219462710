/// <reference path="../index.d.ts" />
import ReactDOM from 'react-dom'
import * as React from 'react'
import { App } from './App'
import './styles/main.scss'
import type { TestResultStateEnum } from '@frontend/dashboard/src/graphql-codegen-operations.gen'
import type { NavigateFn } from '@reach/router'

// Reporter internals expect a
// global Cypress object to exist:
window.Cypress = window.Cypress ?? {}

export interface MountTestReplayProps {
  replayUrl: string
  assetsUrl: string | null
  rangeHeader: string | null
  testId: string
  container: HTMLElement
  hasPaidPlan: boolean
  buildNumber: number
  projectId: string
  testUuid: string
  sqlJsUrl: string
  titleParts: string[]
  testState: TestResultStateEnum
  testConfig: {
    viewportHeight: number
    viewportWidth: number
    testIsolation: boolean
  }
  onDBLoaded: (v: { sizeOfDownload: number }) => void
  navigate: NavigateFn
  features: {
    canViewCanvas: boolean
    inlineBlobUrls: boolean
    preserveCssLinks: boolean
  }
}

export function mountTestReplay(props: MountTestReplayProps) {
  ReactDOM.render(
    <App
      s3BucketUrl={props.replayUrl}
      rangeHeader={props.rangeHeader}
      s3BucketAssetsUrl={props.assetsUrl}
      testId={props.testId}
      testUuid={props.testUuid}
      projectId={props.projectId}
      buildNumber={props.buildNumber}
      sqlJsUrl={props.sqlJsUrl}
      hasPaidPlan={props.hasPaidPlan}
      titleParts={props.titleParts}
      testState={props.testState}
      testConfig={props.testConfig}
      onDBLoaded={props.onDBLoaded}
      navigate={props.navigate}
      features={props.features}
    />,
    props.container
  )
  return () => {
    ReactDOM.unmountComponentAtNode(props.container)
  }
}
