import type React from 'react'
import type {
  EventsPayload,
  ElementToHighlight,
} from '@app/capture-protocol/src/db/schemas/latest'
import type { ElementToHighlight as ElementToHighlightV9 } from '@app/capture-protocol/src/db/schemas/v9'
import { getBox, withScroll } from 'css-box-model'
import type { NodeMap } from './Replay/nodeMap'
import { warnOnce } from './Replay/warnOnce'
import { isArray } from 'lodash'

const isElementToHighlightWithSelector = (
  element: ElementToHighlight | ElementToHighlightV9
): element is ElementToHighlightV9 => {
  return Object.hasOwnProperty.call(element, 'selector')
}
const isElementToHighlightWithElementId = (
  element: ElementToHighlight | ElementToHighlightV9
): element is ElementToHighlight => {
  return Object.hasOwnProperty.call(element, 'elementId')
}

export const applyHighlightToElements = (ctx: {
  highlightElementSelectors: ElementToHighlight[] | ElementToHighlightV9[]
  actionHitbox: EventsPayload['cypress']['log:changed']['coords']
  iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
  nodeMap: NodeMap
}) => {
  for (const frameId of ctx.nodeMap.frameIds) {
    const pastHighlights = ctx.nodeMap.queryAllInFrame(
      frameId,
      '.__tr__cypress-highlight'
    )
    pastHighlights?.forEach((cleanup) => {
      cleanup.remove()
    })
  }

  // highlighting too many elements can crash
  // the browser, only take up to the first 1000
  const maxSupportedElements = ctx.highlightElementSelectors.slice(0, 1000)

  maxSupportedElements.forEach((element) => {
    let el: Element | null | undefined
    let identifier: string | undefined

    // Get the element to highlight. There are two schemas for this:
    // - ElementToHighlightV9: { frameId: string, selector: string[] }
    // - ElementToHighlight: { elementId: string }
    //
    // We need to handle both schemas.
    if (isElementToHighlightWithSelector(element)) {
      const { frameId, selector } = element
      el = frameId
        ? ctx.nodeMap.queryInFrameByFrameId(frameId, selector)
        : ctx.nodeMap.queryInFrame(
            ctx.iframeRef.current?.contentDocument,
            selector
          )

      if (!el) {
        const selectorString = isArray(selector)
          ? selector.join('->')
          : selector
        identifier = `${selectorString}-${frameId}`
        warnOnce(
          identifier,
          'Failed to highlight with selector: %s and frameId: %s',
          selectorString,
          frameId
        )
        return
      }
    } else if (isElementToHighlightWithElementId(element)) {
      const { elementId } = element
      el = ctx.nodeMap.getNode(elementId) as Element

      if (!el) {
        identifier = `${elementId}`
        warnOnce(
          identifier,
          'Failed to highlight with elementId: %s',
          elementId
        )
        return
      }
    }

    if (!el) {
      warnOnce(`unknown-element`, 'Unknown element to highlight: %o', element)
      return
    }

    let dimensions = getBox(el)
    dimensions = withScroll(dimensions, {
      x: ctx.iframeRef.current?.contentWindow?.scrollX || 0,
      y: ctx.iframeRef.current?.contentWindow?.scrollY || 0,
    })
    const container = document.createElement('div')
    container.classList.add('__tr__cypress-highlight')

    container.style.opacity = `0.7`
    container.style.position = 'absolute'
    // Max zIndex
    container.style.zIndex = '2147483647'
    container.style.top = '0px'
    container.style.left = '0px'
    ;(
      [
        [dimensions.contentBox, '#9FC4E7'],
        [dimensions.paddingBox, '#C1CD89'],
        [dimensions.borderBox, '#FCDB9A'],
        [dimensions.marginBox, '#F9CC9D'],
      ] as [(typeof dimensions)['borderBox'], string][]
    ).forEach(([box, color]) => {
      const div = document.createElement('div')
      div.style.transform = getComputedStyle(el!, null).transform
      div.style.width = `${box.width}px`
      div.style.height = `${box.height}px`
      div.style.top = `${box.y}px`
      div.style.left = `${box.x}px`
      div.style.position = 'absolute'
      div.style.zIndex = '2147483647'
      div.style.backgroundColor = color
      container.prepend(div)
    })

    try {
      ctx.iframeRef.current?.contentDocument?.body.appendChild(container)
    } catch (err) {
      warnOnce(`${identifier}-append-body`, err)
    }
  })

  if (ctx.actionHitbox?.x != null && ctx.actionHitbox?.y != null) {
    // Main Red Circle
    const div = document.createElement('div')
    div.classList.add('__tr__cypress-highlight')
    div.style.width = `10px`
    div.style.height = `10px`
    div.style.backgroundColor = 'red'
    div.style.borderRadius = '5px'
    div.style.boxShadow = 'rgb(51, 51, 51) 0px 0px 5px'

    div.style.top = `${ctx.actionHitbox.y - 5}px`
    div.style.left = `${ctx.actionHitbox.x - 5}px`
    div.style.position = 'absolute'
    div.style.zIndex = '2147483647'

    // Inner Circle
    const innerDiv = document.createElement('div')
    innerDiv.style.position = 'relative'
    const pinkDiv = document.createElement('div')
    pinkDiv.style.position = 'absolute'
    pinkDiv.style.backgroundColor = 'pink'
    pinkDiv.style.top = `3px`
    pinkDiv.style.left = `3px`
    pinkDiv.style.width = `4px`
    pinkDiv.style.height = `4px`
    pinkDiv.style.borderRadius = `5px`

    innerDiv.prepend(pinkDiv)
    div.prepend(innerDiv)
    try {
      ctx.iframeRef.current?.contentDocument?.body?.appendChild(div)
    } catch (err) {
      warnOnce(`append-hitbox`, err)
    }
  }
}
