import type { EventsPayload as EventsPayloadV9 } from '@app/capture-protocol/src/db/schemas/v9'
import type { EventsPayload } from '@app/capture-protocol/src/db/schemas/latest'
import type { NodeMap } from './Replay/nodeMap'

type SelectorPayload =
  | EventsPayloadV9['cdp']['dom:element-focused']
  | EventsPayloadV9['cdp']['dom:element-blurred']
  | EventsPayloadV9['cdp']['dom:element-scrolled']
  | EventsPayloadV9['cdp']['dom:radio-input-changed']
  | EventsPayloadV9['cdp']['dom:checkbox-input-changed']
  | EventsPayloadV9['cdp']['dom:select-input-changed']
  | EventsPayloadV9['cdp']['dom:text-input-changed']
type IdPayload =
  | EventsPayload['cdp']['dom:element-focused']
  | EventsPayload['cdp']['dom:element-blurred']
  | EventsPayload['cdp']['dom:element-scrolled']
  | EventsPayload['cdp']['dom:radio-input-changed']
  | EventsPayload['cdp']['dom:checkbox-input-changed']
  | EventsPayload['cdp']['dom:select-input-changed']
  | EventsPayload['cdp']['dom:text-input-changed']
  | EventsPayload['cdp']['dom:canvas-snapshot']
type IdOrSelectorPayload = SelectorPayload | IdPayload

const hasElementSelector = (
  payload: IdOrSelectorPayload
): payload is SelectorPayload => {
  return Object.hasOwnProperty.call(payload, 'elementSelector')
}

const hasElementId = (payload: IdOrSelectorPayload): payload is IdPayload => {
  return Object.hasOwnProperty.call(payload, 'elementId')
}

export const getElementFromPayload = (
  nodeMap: NodeMap,
  payload: IdOrSelectorPayload
) => {
  if (hasElementSelector(payload)) {
    return nodeMap.queryInFrameByFrameId(
      payload.frameId,
      payload.elementSelector
    )
  }

  if (hasElementId(payload)) {
    return nodeMap.getNode(payload.elementId) as HTMLElement
  }

  return undefined
}
