import 'css-chunk:src/components/ReplayBody/CommandLogs/CommandLogs.module.scss';export default {
  "status-icon-container": "_status-icon-container_1b2e9_7",
  "command-logs-container": "_command-logs-container_1b2e9_16",
  "header-container": "_header-container_1b2e9_24",
  "titles-container": "_titles-container_1b2e9_32",
  "title": "_title_1b2e9_32",
  "subtitle": "_subtitle_1b2e9_47",
  "attempt-wrapper": "_attempt-wrapper_1b2e9_57",
  "tooltip-container": "_tooltip-container_1b2e9_61",
  "tooltip-title-row": "_tooltip-title-row_1b2e9_64",
  "tooltip-title": "_tooltip-title_1b2e9_64",
  "tooltip-title-section": "_tooltip-title-section_1b2e9_78",
  "tooltip-title-chevron": "_tooltip-title-chevron_1b2e9_83"
};