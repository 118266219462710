import initSqlJs, { SqlValue } from 'sql.js'
import * as flatted from 'flatted'
import {
  ConsoleItemFactory,
  formatConsoleEvents,
} from '../utils/formatConsoleEvents'
import dedent from 'dedent'
import type {
  NetworkResponses,
  Events,
} from '@app/capture-protocol/src/schema-types/v1/db'
import type { HashedData } from '@app/capture-protocol/src/schema-types/v2/db'
import pako from 'pako'
import type {
  ConsoleApiCalled,
  ExceptionThrown,
  TestLifecycleStartEventWithDependents,
  CommandLog,
  TestLifecycleEndEvent,
} from '@app/capture-protocol/src/db/schemas/latest'
import {
  GetAttemptCommandsQueryResult,
  getAttemptCommandsQuery,
} from './getAttemptCommandsQuery'

import type { ConsolePropInput } from '../utils/logger'

export type AttemptOptionInfo = {
  min: number
  max: number
  cliffAt?: number
  status: RunnableStatus
}

export type PrepareDatabase = {
  type: 'GET_REPLAY_DATA'
  payload: {
    testId: string
    url: string
    sqlJsUrl: string
    assetsUrl: string | null
    rangeHeader: string
    features: {
      canViewCanvas: boolean
      inlineBlobUrls: boolean
      preserveCssLinks: boolean
    }
    initAttempt?: number
  }
}

export type GetNetworkResponse = {
  type: 'GET_NETWORK_RESPONSE'
  payload: {
    attNum: number
    reqId: string
  }
}

export type GetNetworkRequest = {
  type: 'GET_NETWORK_REQUEST'
  payload: {
    attNum: number
    reqId: string
  }
}

export type ReplayDataReady = {
  type: 'REPLAY_DATA_READY'
  payload: {
    testRoot: any
    testAttempts: (TestAttempt | null)[]
    attemptOptions: AttemptOptionInfo[]
    sizeOfDownload: number
    postponed: number[]
  }
}

export type ReplayDataPostponedReady = {
  type: 'REPLAY_DATA_POSTPONED_ATTEMPT_READY'
  payload: {
    attNum: number
    attemptData: TestAttempt
  }
}

export type ReplayDataError = {
  type: 'REPLAY_DATA_ERROR'
  payload: {
    error: any
  }
}

export type GetFullLogMessage = {
  type: 'GET_FULL_LOG_MESSAGE'
  payload: {
    eventId: string
  }
}

export type FullLogMessageReady = {
  type: 'FULL_LOG_MESSAGE_READY'
  payload: {
    filename: string
    text: string
  }
}

export type GetConsolePropsMessage = {
  type: 'GET_CONSOLE_PROPS_MESSAGE'
  payload: {
    attNum: number | string
    logId: string
  }
}

export type ConsolePropsMessageReady = {
  type: 'CONSOLE_PROPS_MESSAGE_READY'
  payload: {
    message: ConsolePropInput
  }
}

export type SentryStatus = 'ok' | 'cancelled' | 'internal_error'
export type SentryTransaction = {
  type: 'SENTRY_TRANSACTION'
  payload: {
    status: SentryStatus
    startTimestamp: number
    endTimestamp: number
    description: string
    op: string
    tags?: {
      [key: string]:
        | number
        | string
        | boolean
        | bigint
        | symbol
        | null
        | undefined
    }
  }
}
export type SentryError = {
  type: 'SENTRY_ERROR'
  payload: {
    error: Error
    tags?: {
      [key: string]:
        | number
        | string
        | boolean
        | bigint
        | symbol
        | null
        | undefined
    }
  }
}

type WorkerMessageEvent = MessageEvent<
  | GetNetworkRequest
  | PrepareDatabase
  | GetNetworkResponse
  | GetFullLogMessage
  | GetConsolePropsMessage
>

export type ClientMessageEvent = MessageEvent<
  ReplayDataReady | ReplayDataError | ReplayDataPostponedReady
>

interface NetworkResponsePayload extends NetworkResponses {
  [x: string]: SqlValue
  is_base64_encoded: number
  hash: string
  data: string
  is_external: number | null
  external_offset: number | null
  external_size: number | null
}

export type NetworkResponseReady = {
  type: 'NETWORK_RESPONSE_READY'
  payload: NetworkResponsePayload
}

export type NetworkRequestReady = {
  type: 'NETWORK_REQUEST_READY'
  payload: NetworkResponsePayload
}

export type ConsolePropsCommandLog = CommandLog & {
  hasConsoleProps: boolean
}

// todo: what statuses do we expect to support in test-replay?
export type RunnableStatus = 'passed' | 'failed' | 'pending' | 'skipped'
type RequestId = string

export type ConsoleEvent = {
  id: string
  compareKey: string
  eventId: string | number
  variant: 'verbose' | 'info' | 'warning' | 'error'
  message: string
  lowerCaseMessage: string
  format: 'italic' | null
  summaryCount: number
  eventStart: number
  eventEnd: number
  table?: { index: string; [k: string]: string }[]
  callFrames: Record<string, string>[]
  // size analysis:
  originalSize: string
  clipped: boolean
}

export type TestAttemptEventCommand = CypressAppEvent<
  ConsolePropsCommandLog | TestLifecycleEndEvent
>

export type TestAttempt = {
  id: string
  cliffAt?: number
  min: number
  max: number
  status: RunnableStatus
  events: {
    urls: CypressAppEvent[] // todo
    commands: TestAttemptEventCommand[] // todo
    dom: CypressAppEvent[] // todo
    viewportChanged: CypressAppEvent[] // todo
    domNetworkAssets: ImagesAndStylesAssets
    domCanvasAssets?: CanvasAssets
    network: [RequestId, CypressAppEvent[]][]
    consoleLogs: ConsoleEvent[]
    pageLoadings: CypressAppEvent[]
    aboutBlank: {
      timeBeforeLast?: number
    }
  }
}

interface RawCypressAppEvent {
  id: number | null
  source?: string
  type: string
  payload?: string | null
  timestamp: number
  runnable_id?: string
  attempt?: number
}

export interface CypressAppEvent<P = ReturnType<typeof JSON.parse>>
  extends Omit<RawCypressAppEvent, 'payload' | 'type'> {
  type: RawCypressAppEvent['type']
  payload?: P
}

type Assets = {
  type: 'Stylesheet' | 'Image' | 'Font'
  request_url: string
  attempt: number
  data: string
  is_base64_encoded: number
  mime_type: string
  hash: string
}

export type ImagesAndStylesAssets = {
  pathHash: { [k: string]: string }
  hashData: {
    [k: string]: {
      body?: string
      proxyUrl?: string
      isBase64: boolean
      mimeType: string
      type: Assets['type']
      hash: string
    }
  }
}

export type CanvasAssets = {
  imageData: {
    [k: string]: {
      bitmap: ImageBitmap
    }
  }
}

function withParsedPayload<
  T extends Pick<RawCypressAppEvent, 'payload'>,
  P extends Record<any, any>,
>(
  results: T[],
  // if possible, reduce loops by doing any
  // custom changes within the parser itself:
  formatSideEffect?: (result: { payload?: P } & Omit<T, 'payload'>) => void
): Array<{ payload?: P } & Omit<T, 'payload'>> {
  return results.map((r) => {
    if (r.payload) {
      ;(r as { payload?: P } & Omit<T, 'payload'>).payload = JSON.parse(
        r.payload
      ) as P
    }
    formatSideEffect?.(r as { payload?: P } & Omit<T, 'payload'>)
    return r as { payload?: P } & Omit<T, 'payload'>
  })
}

function shortenNetworkEventUrl(url: string) {
  const ellipsisChar = '…'

  // Data urls are simply truncated to 20 characters
  // Ex: 'data:foobarbazfooba…'
  if (url.startsWith('data:') && url.length > 20) {
    return `${url.substring(0, 19)}${ellipsisChar}`
  }

  let validatedUrlSegment

  try {
    const parsedUrl = new URL(url)

    if (!['http:', 'https:'].includes(parsedUrl.protocol)) {
      // protocols other than http/https are not manipulated beyond
      // max length truncation.
      // Ex: 'blob:http://www.foobarbaz.com/foo'
      validatedUrlSegment = url
    } else {
      // if a path is found, the terminal segment is used
      // along with any search params
      // Ex: 'http://www.foo.com/bar/baz?param=1' -> 'baz?param=1'
      validatedUrlSegment = `${parsedUrl.pathname.split('/').pop()}${
        parsedUrl.search
      }`
    }
  } catch (e) {
    // the URL constructor throws if it cannot parse the value.
    // in these cases, we just fall back to the given url value.
    validatedUrlSegment = url
  }

  const validatedUrlSegmentLength = validatedUrlSegment.length
  if (validatedUrlSegmentLength <= 100) {
    return validatedUrlSegment
  }

  return `${validatedUrlSegment.substring(
    0,
    50
  )}${ellipsisChar}${validatedUrlSegment.substring(
    validatedUrlSegmentLength - 49
  )}`
}

class DBApi {
  _attemptNumbersCache: number[] | null = null
  _attemptOptionsCache: { [k: number]: AttemptOptionInfo } = {}

  _testDb: initSqlJs.Database | null = null
  _testId: string | null = null
  _dependentRunnables: TestLifecycleStartEventWithDependents['dependentRunnables'] =
    []
  _protocolVersion: {
    mountVersion: number
    schemaVersion: number
    captureProtocolScriptId: number
  } | null = null
  _assetsUrl: string | null = null

  get testDb() {
    if (!this._testDb) {
      throw new Error('Database has not been initialized')
    }
    return this._testDb
  }

  get dependentRunnables() {
    return this._dependentRunnables.map((dr) => dr.id)
  }

  get protocolVersion(): {
    mountVersion: number
    schemaVersion: number
    captureProtocolScriptId: number
  } {
    if (!this._protocolVersion) {
      this._protocolVersion = this.getProtocolMetadata()
    }
    return this._protocolVersion
  }

  private pushSentryTransaction(payload: SentryTransaction['payload']) {
    worker.postMessage({
      type: 'SENTRY_TRANSACTION',
      payload: {
        ...payload,
        sampled: true,
        startTimestamp: payload.startTimestamp / 1000,
        endTimestamp: payload.endTimestamp / 1000,
      },
    } as SentryTransaction)
  }

  private pushSentryError(
    err: Error,
    tags: SentryError['payload']['tags'] = {}
  ) {
    worker.postMessage({
      type: 'SENTRY_ERROR',
      payload: {
        error: err,
        tags: {
          ...tags,
          testId: this._testId,
          assetsUrl: this._assetsUrl,
        },
      },
    } as SentryError)
  }

  createDb(
    testId: string,
    url: string,
    sqlJsUrl: string,
    assetsUrl: string | null,
    rangeHeader: string | null
  ) {
    const startTimestamp = Date.now()
    let startUnzippingTimestamp: number = 0
    let loadedTimestamp: number = 0
    let byteLength = 0
    let zippedLength = 0
    let status: SentryStatus = 'ok'
    return fetch(url, {
      headers: { ...(rangeHeader && { Range: rangeHeader }) },
    })
      .then(async (res) => {
        startUnzippingTimestamp = Date.now()
        const SQL = await initSqlJs({
          locateFile: (file: string) => `${sqlJsUrl}${file}`,
        })
        const file = new Uint8Array(
          await res.arrayBuffer().then((buff) => {
            zippedLength = buff.byteLength
            return pako.inflate(buff)
          })
        )
        this._testDb = new SQL.Database(file)
        loadedTimestamp = Date.now()
        this._testId = testId
        this._assetsUrl = assetsUrl
        this._dependentRunnables = this.getDependentRunnables()
        this._protocolVersion = this.getProtocolMetadata()
        byteLength = file.byteLength
        return file.byteLength
      })
      .catch((err) => {
        console.error(err)
        status = 'internal_error'
        throw err
      })
      .finally(() => {
        this.pushSentryTransaction({
          startTimestamp,
          endTimestamp: startUnzippingTimestamp,
          op: 'worker.createDb',
          description: 'fetch',
          tags: {
            url,
            testId,
            compressedFileSize: zippedLength,
          },
          status,
        })
        this.pushSentryTransaction({
          startTimestamp: startUnzippingTimestamp,
          endTimestamp: loadedTimestamp,
          op: 'worker.createDb',
          description: 'load',
          tags: {
            url,
            testId,
            uncompressedFileSize: byteLength,
          },
          status,
        })
      })
  }

  private select<T>(query: string, bindings?: initSqlJs.BindParams): T[] {
    const startTimestamp = Date.now()
    const cleanQuery = dedent(query)

    const stmt = this.testDb.prepare(cleanQuery, bindings)
    const result: T[] = []
    while (stmt.step()) {
      result.push(stmt.getAsObject() as T)
    }
    // prevent memory leak:
    stmt.free()

    // console.log(cleanQuery, bindings, Date.now() - startTimestamp);
    this.pushSentryTransaction({
      startTimestamp,
      endTimestamp: Date.now(),
      op: 'worker.query',
      description: cleanQuery,
      tags: {
        testId: this._testId,
        query: cleanQuery,
        bindings: JSON.stringify(bindings),
      },
      status: 'ok',
    })
    return result
  }

  private getProtocolMetadata(): {
    mountVersion: number
    schemaVersion: number
    captureProtocolScriptId: number
  } {
    const qRes = this.select<{ table_exists: number }>(
      `SELECT count(*) as table_exists FROM sqlite_master WHERE type='table' AND name=?`,
      ['metadata']
    )
    const tableExists = Boolean(qRes[0]?.table_exists)
    const metadata = {
      mountVersion: 0,
      schemaVersion: 0,
      captureProtocolScriptId: 0,
    }
    if (tableExists) {
      const metadataQRes = this.select<{
        mount_version: number
        schema_version: number
        capture_protocol_script_id: number
      }>(`SELECT * from metadata limit 1`)[0]
      metadata.mountVersion = metadataQRes?.mount_version
        ? metadataQRes.mount_version
        : metadata.mountVersion
      metadata.schemaVersion = metadataQRes?.schema_version
        ? metadataQRes.schema_version
        : metadata.schemaVersion
      metadata.captureProtocolScriptId =
        metadataQRes?.capture_protocol_script_id
          ? metadataQRes.capture_protocol_script_id
          : metadata.captureProtocolScriptId
    }
    return metadata
  }

  // todo: typescript
  private getTestRoot() {
    const result = this.select<Events>(
      `
      SELECT *
      FROM events
      WHERE type = ?
      AND payload IS NOT NULL;
    `,
      ['runnables:ready']
    )

    const testRoot = result[0]

    if (!testRoot?.payload) {
      return {}
    }
    // there should only be one:
    return JSON.parse(testRoot.payload)
  }

  private getDependentRunnables(): TestLifecycleStartEventWithDependents['dependentRunnables'] {
    const result = this.select<{ dependentRunnables: string }>(
      `
      SELECT json_extract(payload, '$.dependentRunnables') as dependentRunnables
      FROM events
      WHERE type = 'test:before:run:async'
      AND runnable_id = ?;
    `,
      [this._testId]
    )
    // there should only be one:
    return result[0]?.dependentRunnables
      ? JSON.parse(result[0]?.dependentRunnables)
      : []
  }

  private async getImagesAndStylesAssets(attNum: number) {
    const attemptFilter = this.dependentRunnables.length > 0 ? '<=' : '='
    const result = this.select<{
      request_url: string
      attempt: number
      type: string
      is_base64_encoded: number
      mime_type: string
      hash: string
    }>(
      `
      SELECT
        request_url,
        attempt,
        type,
        is_base64_encoded,
        mime_type,
        hash
      FROM network_responses
      INNER JOIN hashed_data ON body_hash = hash
      WHERE (
        (type = 'Other' AND mime_type = 'image/svg+xml')
        OR (type in ('Stylesheet', 'Image', 'Font'))
      )
      AND(
        (runnable_id = ? AND attempt ${attemptFilter} ?)
        OR (runnable_id IS NULL)
        OR (runnable_id IN (${this.dependentRunnables
          .map(() => '?')
          .join(', ')}))
      )
      ORDER BY loading_finished_timestamp DESC
    `,
      [this._testId, attNum, ...this.dependentRunnables]
    ) as Assets[]

    const data: ImagesAndStylesAssets = { pathHash: {}, hashData: {} }
    result.forEach((row) => {
      const requestUrl = new URL(row.request_url)
      const origin = requestUrl.origin
      const pathname = requestUrl.pathname
      const possiblePath = row.request_url.replace(origin, '')
      data['pathHash'][row.request_url] = row.hash
      data['pathHash'][possiblePath] = row.hash
      data['pathHash'][pathname] = row.hash
      if (!data['hashData'][row.hash]) {
        data['hashData'][row.hash] = {
          isBase64: row.is_base64_encoded === 1,
          body: '', // Updated as part of separate query
          mimeType: row.mime_type,
          type: row.type,
          hash: row.hash,
        }
      }
    })

    const uniqueHashes = Object.keys(data['hashData'])
    const hashResults = this.select<HashedData>(
      `SELECT * FROM hashed_data where hash IN (${uniqueHashes
        .map((r) => '?')
        .join(', ')})`,
      uniqueHashes
    )
    await Promise.all(
      hashResults
        .filter((res) => {
          const hashData = data['hashData'][res.hash]
          if (!res.is_external && hashData) {
            hashData.body = res.data as NonNullable<HashedData['data']>
          }
          return res.is_external
        })
        .map(async (res) => {
          const hashData = data['hashData'][res.hash]

          if (hashData) {
            hashData.body = await this.getExternalAsset(
              res.external_offset,
              res.external_size,
              !hashData.isBase64 && ['Image', 'Font'].includes(hashData.type)
            )
            hashData.isBase64 =
              hashData.isBase64 ||
              (['Image', 'Font'].includes(hashData.type) &&
                this.protocolVersion.schemaVersion >= 6)
          }
        })
    )
    return data
  }

  private async getCanvasAssets(attNum: number) {
    const result: { hash: string }[] = []

    // if we have dependent runnables, we need to find the last snapshot
    // from the previous test for each canvas element
    if (this.dependentRunnables.length > 0) {
      const lastRunnable =
        this.dependentRunnables[this.dependentRunnables.length - 1]
      const snapshots = this.select<{
        elementId: string
        hash: string
      }>(
        `
        SELECT
          json_extract(payload, '$.hashValue') as hash_value,
          hash,
          json_extract(payload, '$.elementId') as elementId
        FROM events
        INNER JOIN hashed_data ON hash_value = hash
        WHERE runnable_id = ?
        AND type = 'dom:canvas-snapshot'
        AND source = 'cdp'
        ORDER BY timestamp ASC, id ASC
      `,
        [lastRunnable as string]
      )

      // find last snapshot for each canvas element
      const lastSnapshots: Record<string, string> = {}
      snapshots.forEach((snapshot) => {
        lastSnapshots[snapshot.elementId] = snapshot.hash
      })

      result.push(...Object.values(lastSnapshots).map((hash) => ({ hash })))
    }

    // find all snapshots for the current test
    result.push(
      ...this.select<{
        hash: string
      }>(
        `
        SELECT
          json_extract(payload, '$.hashValue') as hash_value,
          hash
        FROM events
        INNER JOIN hashed_data ON hash_value = hash
        WHERE runnable_id = ?
        AND attempt = ?
        AND type = 'dom:canvas-snapshot'
        AND source = 'cdp'
        ORDER BY timestamp ASC, id ASC
      `,
        [this._testId, attNum]
      )
    )

    if (!result.length) {
      return undefined
    }

    const uniqueHashes = Array.from(new Set(result.map((r) => r.hash)))
    const hashResults = this.select<HashedData>(
      `SELECT * FROM hashed_data where hash IN (${uniqueHashes
        .map((r) => '?')
        .join(', ')})`,
      uniqueHashes
    )

    // TODO: (canvas) this currently loads all canvas assets into memory
    // this is not ideal for a large number of assets, and should be optimized
    // see: https://github.com/cypress-io/cypress-services/issues/9465
    const data: CanvasAssets = { imageData: {} }
    await Promise.all(
      hashResults.map(async (res) => {
        try {
          const asset = await this.getRawExternalAsset(
            res.external_offset,
            res.external_size
          )

          if (asset) {
            const blob = new Blob([asset])
            const image = await createImageBitmap(blob)

            data.imageData[res.hash] = { bitmap: image }
          }
        } catch (err) {
          this.pushSentryError(err as Error)
          return
        }
      })
    )

    return data
  }

  private getAttemptStatus(attNum: number): RunnableStatus {
    const res = this.select<{ state: string }>(
      `
      SELECT json_extract(payload, '$.state') as state
      FROM events
      WHERE runnable_id = ?
      AND attempt = ?
      AND type IN ('test:after:run', 'test:after:run:async');`,
      [this._testId, attNum]
    )
    return (res[0]?.state as RunnableStatus) || 'failed'
  }

  private formatNetworkEvents(
    events: ReturnType<typeof this.getAllEventsOfTypeMatch>
  ) {
    const byId: Record<string, CypressAppEvent[]> = {}
    const firstTimestampsById: Record<string, number> = {}
    for (const ev of events) {
      if (ev.payload.request?.url) {
        ev['payload']['request']['lowerCaseUrl'] =
          ev.payload.request.url.toLowerCase()
        ev['payload']['request']['shortUrl'] = shortenNetworkEventUrl(
          ev.payload.request.url
        )
      }
      const id = ev.payload.requestId
      const byIdVal = (byId[id] ||= [])
      byIdVal.push(ev)

      // the initial timestamp used to sort the events is stored
      // in a separate mapping for performant lookups at sort time
      if (ev.type === 'network:request-will-be-sent') {
        firstTimestampsById[id] = ev.timestamp
      }
    }

    return Object.entries(byId).sort((a, b) => {
      const startA = firstTimestampsById[a[0]] ?? 0
      const startB = firstTimestampsById[b[0]] ?? 0
      return startA - startB
    })
  }

  private getAttemptNumbers() {
    if (this._attemptNumbersCache) {
      return this._attemptNumbersCache
    }
    const result = this.select<{ attempt: number }>(
      `
      SELECT DISTINCT(attempt)
      FROM events
      WHERE attempt IS NOT NULL
      AND runnable_id = ?
      `,
      [this._testId]
    ).map(({ attempt }) => attempt)
    this._attemptNumbersCache = result
    return result
  }

  private getAttemptStartTime(attNum: number) {
    const result = this.select<{ timestamp: number }>(
      `
        SELECT timestamp
        FROM events
        WHERE runnable_id = ?
        AND attempt = ?
        ORDER BY timestamp ASC, id ASC
        LIMIT 1
      `,
      [this._testId, attNum]
    )
    return result?.[0]?.timestamp ?? 0
  }

  private getAttemptEndTime(attNum: number) {
    const result = this.select<{ timestamp: number }>(
      `
        SELECT timestamp
        FROM events
        WHERE runnable_id = ?
        AND attempt = ?
        ORDER BY timestamp DESC, id DESC
        LIMIT 1
      `,
      [this._testId, attNum]
    )
    return result?.[0]?.timestamp ?? 0
  }

  private getAllEventsOfType(
    eventType: string | string[],
    attNum: number,
    includeDependentRunnables: boolean = false
  ) {
    let typeBindings: string[]
    if (typeof eventType === 'string') {
      typeBindings = [eventType]
    } else {
      typeBindings = eventType
    }

    // Create a string with the
    // correct number of placeholders:
    const placeholders = typeBindings.map(() => '?').join(',')
    const attemptFilter = includeDependentRunnables ? '<=' : '='

    const results = this.select<Events>(
      `
      SELECT *
      FROM events
      WHERE events.type IN (${placeholders})
      AND (
        (runnable_id = ? AND attempt ${attemptFilter} ?)
        ${
          includeDependentRunnables
            ? `OR (runnable_id IN (${this.dependentRunnables
                .map(() => '?')
                .join(', ')}))`
            : ''
        }
      )
      ORDER BY timestamp ASC, id ASC;
    `,
      [
        ...typeBindings,
        this._testId,
        attNum,
        ...(includeDependentRunnables ? this.dependentRunnables : []),
      ]
    )
    return withParsedPayload<Events, any>(results) as CypressAppEvent[]
  }

  private getAllEventsOfTypeMatch(
    eventType: string,
    attNum: number,
    includeDependentRunnables: boolean = false
  ) {
    const attemptFilter = includeDependentRunnables ? '<=' : '='
    const results = this.select<CypressAppEvent>(
      `
      SELECT
        *,
        json_extract(payload, '$.sequence') as domSequenceNumber
      FROM events
      WHERE events.type like ?
      AND (
        (runnable_id = ? AND attempt ${attemptFilter} ?)
        ${
          includeDependentRunnables
            ? `OR (runnable_id in (${this.dependentRunnables
                .map(() => '?')
                .join(', ')} ))`
            : ''
        }
      )
      ORDER BY timestamp ASC, domSequenceNumber ASC, Id ASC;
    `,
      [
        `${eventType}%`,
        this._testId,
        attNum,
        ...(includeDependentRunnables ? this.dependentRunnables : []),
      ]
    )
    return withParsedPayload<CypressAppEvent, any>(results)
  }

  // Do inner join with new console table
  private getAttemptCommands(attNum: number, lastMeaningfulTimestamp?: number) {
    const results = this.select<GetAttemptCommandsQueryResult>(
      getAttemptCommandsQuery(this.protocolVersion.schemaVersion),
      [this._testId, attNum]
    )
    const commands = withParsedPayload(results, (appEvent) => {
      if (appEvent.payload) {
        ;(appEvent.payload as ConsolePropsCommandLog).hasConsoleProps = Boolean(
          appEvent.has_console_props
        )
      }

      if (appEvent?.payload?.snapshots?.length) {
        const eventTimestamp = appEvent.timestamp
        const firstSnapshotTimestamp = appEvent.payload.snapshots[0]?.timestamp

        if (firstSnapshotTimestamp) {
          // If the event has at least one snapshot, and the timestamp for the
          // first snapshot is earlier than the event's timestamp, then we
          // update the event timestamp to equal the snapshot timestamp value.
          // This prevents commands from being filtered from the reporter when
          // their snapshots are pinned, as events beyond the pinned snapshot
          // timestamp are expected to be hidden.
          appEvent.timestamp = Math.min(eventTimestamp, firstSnapshotTimestamp)
        }
      }
    })

    return commands
  }

  private getFirstFailureTime(attNum: number) {
    const result = this.select<{ state: string; timestamp: number }>(
      `
      SELECT
        json_extract(payload, '$.state') AS state,
        json_extract(payload, '$.event') AS nonCommand,
        timestamp
      FROM events
      WHERE events.type like 'log:%'
      AND nonCommand = 0
      AND state = 'failed'
      AND runnable_id = ?
      AND attempt = ?
      ORDER BY timestamp ASC, id ASC
      LIMIT 1;
    `,
      [this._testId, attNum]
    )
    return result?.[0]?.timestamp as number | undefined
  }

  private getEventBeforeLastBlankUrl(attNum: number) {
    // Selects the first timestamp before the last url:changed "" event
    const result = this.select<{ timestamp: number }>(
      `
      SELECT timestamp from events where timestamp < (
        SELECT timestamp
                FROM events
                WHERE 1=1
                AND runnable_id = ?
                AND attempt = ?
                AND type = 'url:changed'
                AND payload = '""'
                ORDER BY timestamp DESC
                LIMIT 1
        )
        ORDER BY
        timestamp DESC
        LIMIT 1;
    `,
      [this._testId, attNum]
    )
    // There might be multiple results, but we only care to find the LAG() of the last known url:changed="".
    // At this time, Cypress App is expected to always generate a blank transition at the end of every test:
    return result?.pop()?.timestamp
  }

  public async getFormattedAttempt(
    attNum: number,
    features: { canViewCanvas: boolean }
  ) {
    const lastMeaningfulFrame = this.getEventBeforeLastBlankUrl(attNum)
    const baseInfo = this.getOptionInfo(attNum)
    const [domNetworkAssets, domCanvasAssets] = await Promise.all([
      this.getImagesAndStylesAssets(attNum),
      features.canViewCanvas ? this.getCanvasAssets(attNum) : undefined,
    ])

    return {
      ...baseInfo,
      id: String(attNum),
      events: {
        aboutBlank: {
          timeBeforeLast: lastMeaningfulFrame,
        },
        commands: this.getAttemptCommands(attNum, lastMeaningfulFrame),
        urls: this.getAllEventsOfType('url:changed', attNum, true),
        pageLoadings: this.getAllEventsOfType('page:loading', attNum),
        dom: this.getAllEventsOfTypeMatch('dom:', attNum, true),
        network: this.formatNetworkEvents(
          this.getAllEventsOfTypeMatch('network:', attNum)
        ),
        consoleLogs: formatConsoleEvents(
          this.getAllEventsOfType(
            ['runtime:console-api-called', 'runtime:exception-thrown'],
            attNum
          )
        ),
        viewportChanged: this.getAllEventsOfType(
          'viewport:changed',
          attNum,
          true
        ),
        domNetworkAssets,
        domCanvasAssets,
      },
    } as TestAttempt
  }

  private getOptionInfo(attNum: number): AttemptOptionInfo {
    const attemptOptionsCache = this._attemptOptionsCache[attNum]
    if (attemptOptionsCache) {
      return attemptOptionsCache
    }
    const optInfo = {
      max: this.getAttemptEndTime(attNum),
      min: this.getAttemptStartTime(attNum),
      cliffAt: this.getFirstFailureTime(attNum),
      status: this.getAttemptStatus(attNum),
    }
    this._attemptOptionsCache[attNum] = optInfo
    return optInfo
  }

  private getAttemptOptions() {
    // minimum amount of data required to
    // populate the attempt-picker dropdown:
    const cb = this.getOptionInfo.bind(this)
    return this.getAttemptNumbers().map(cb)
  }

  private getTestAttempts(
    initAtt: number,
    features: { canViewCanvas: boolean }
  ) {
    // fallback to initAtt=1 if the initAtt provided is invalid;
    // e.g., in case url has an out-of-bounds `att` in query params:
    const attemptNumbers = this.getAttemptNumbers()
    if (!attemptNumbers.includes(initAtt)) {
      initAtt = 1
    }

    const postponed: number[] = []
    const list = this.getAttemptNumbers().map((attNum) => {
      if (attNum === initAtt) {
        return this.getFormattedAttempt(attNum, features)
      }
      postponed.push(attNum)
      return null
    })
    return {
      list,
      postponed,
    }
  }

  public getLogMessage(eventId: string | number) {
    const logItem = this.select<{ payload: string; type: string }>(
      `
      SELECT *
      FROM events
      WHERE id = ?
      AND runnable_id = ?
    `,
      [eventId, this._testId]
    )?.[0]

    if (!logItem) {
      return {
        filename: '',
        text: '',
      }
    }

    // add more types and conditional if needed:
    const payload = JSON.parse(logItem.payload) as
      | ConsoleApiCalled
      | ExceptionThrown

    if (logItem.type === 'runtime:exception-thrown') {
      return {
        filename: 'runtime:exception-thrown:value.txt',
        text:
          (payload as ExceptionThrown)?.exceptionDetails?.exception
            ?.description ?? '',
      }
    }

    // item.type == "runtime:console-api-called"
    const factory = new ConsoleItemFactory()
    const { message } = factory.getConsoleMessage(
      (payload as ConsoleApiCalled)?.args ?? [],
      false // do not clip
    )
    return {
      filename: `${logItem.type}:value.txt`,
      text: message,
    }
  }

  public async getConsolePropsMessage(
    attNum: number | string,
    logId: string
  ): Promise<ConsolePropInput | undefined> {
    const hasConsolePropsSupport = this.protocolVersion.schemaVersion >= 2
    if (!hasConsolePropsSupport) {
      return
    }
    const result = this.select<{
      is_external?: number
      external_offset?: number
      external_size?: number
    }>(
      `
      SELECT *
      FROM console_props
      LEFT JOIN hashed_data
      ON console_props.props_hash = hashed_data.hash
      WHERE console_props.attempt = ?
      AND runnable_id = ?
      AND command_log_id = ?;
    `,
      [attNum, this._testId, logId]
    )?.[0] as NetworkResponsePayload
    if (!result || !result?.is_external || !this._assetsUrl) {
      return {
        type: 'command',
        name: 'Command details not yet supported',
      }
    }
    return flatted.parse(
      await this.getExternalAsset(result.external_offset, result.external_size)
    )
  }

  public async getNetworkDetails(attNum: number | string, reqId: string) {
    // If we move away from * here we NEED to do separate queries based on version
    const result = this.select<NetworkResponsePayload>(
      `
      SELECT *
      FROM network_responses
      LEFT JOIN hashed_data
      ON network_responses.body_hash = hashed_data.hash
      WHERE network_responses.attempt = ?
      AND runnable_id = ?
      AND request_id = ?;
    `,
      [attNum, this._testId, reqId]
    )?.[0]

    if (!result?.is_external || !this._assetsUrl) {
      return result
    }
    return {
      ...result,
      data: await this.getExternalAsset(
        result.external_offset,
        result.external_size,
        ['Image', 'Font'].includes(result.type)
      ),
    }
  }

  private async getExternalAsset(
    offset?: number | null,
    size?: number | null,
    // Starting in Schema version v6 Images/Fonts are no longer being base64 encoded.
    // We now want to base64 encode the data in the worker in order to keep the api to
    // useDomReplay the same
    shouldConvertToBase64 = false // TODO, we don't have to convert to base64 here if we are using blob urls
  ): Promise<string> {
    const rawData = await this.getRawExternalAsset(offset, size)

    if (!rawData) {
      return ''
    }

    return new Promise((res) => {
      if (shouldConvertToBase64 && this.protocolVersion.schemaVersion >= 6) {
        const reader = new FileReader()
        reader.readAsDataURL(new Blob([rawData]))
        reader.onload = function (event) {
          const base64 = (event.target!.result as string)!.split(
            'base64,'
          )[1] as string
          res(base64)
        }
      } else {
        res(new TextDecoder('utf-8').decode(rawData))
      }
    })
  }

  public async getRawExternalAsset(
    offset?: number | null,
    size?: number | null
  ): Promise<Uint8Array | undefined> {
    const start = offset ?? 0
    const end = start + (size ?? 0) - 1
    const assetUrl = new URL(this._assetsUrl!)
    assetUrl.searchParams.append('cacheKey', [start, end].join('_'))

    try {
      const res = await fetch(assetUrl.toString(), {
        headers: {
          Range: `bytes=${start}-${end}`,
        },
      })
      const arrayBuffer = await res.arrayBuffer()
      return pako.inflate(arrayBuffer)
    } catch (err) {
      this.pushSentryError(err as Error, { offset, size })
      return
    }
  }

  public async getNetworkRequestDetails(
    attNum: number | string,
    reqId: string
  ): Promise<NetworkResponsePayload> {
    const hasExternalNetworkRequestSupport =
      this.protocolVersion.schemaVersion >= 2

    if (!hasExternalNetworkRequestSupport) {
      const result = this.select<{ data: string }>(
        `SELECT
	        json_extract(payload, '$.request.postData') as data
        FROM events
        WHERE
	        type in('network:request-will-be-sent', 'network:request-will-be-sent-extra-info')
	        AND runnable_id = ?
	        AND attempt = ?
          AND json_extract(payload, '$.requestId') = ?;`,
        [this._testId, attNum, reqId]
      )[0]
      return {
        data: result?.data,
      } as NetworkResponsePayload
    }

    const result = this.select(
      `SELECT *
      FROM network_requests
      LEFT JOIN hashed_data
      ON network_requests.body_hash = hashed_data.hash
      WHERE network_requests.attempt = ?
      AND runnable_id = ?
      AND request_id = ?`,
      [attNum, this._testId, reqId]
    )?.[0] as NetworkResponsePayload

    if (!result?.is_external || !this._assetsUrl) {
      return result
    }

    return {
      ...result,
      data: await this.getExternalAsset(
        result.external_offset,
        result.external_size,
        ['Image', 'Font'].includes(result.type)
      ),
    }
  }

  public async getReplayData(
    initAttempt: number,
    features: { canViewCanvas: boolean }
  ) {
    const { list, postponed } = this.getTestAttempts(initAttempt, features)
    const testAttempts = await Promise.all(list)
    return {
      attemptOptions: this.getAttemptOptions(),
      testRoot: this.getTestRoot(),
      testAttempts,
      postponed,
      sizeOfDownload: -1,
    }
  }
}

const dbApi = new DBApi()
const worker = self as unknown as Worker

worker.addEventListener('message', async (event: WorkerMessageEvent) => {
  switch (event.data.type) {
    case 'GET_REPLAY_DATA': {
      try {
        const bytesDownloaded = await dbApi.createDb(
          event.data.payload.testId,
          event.data.payload.url,
          event.data.payload.sqlJsUrl,
          event.data.payload.assetsUrl,
          event.data.payload.rangeHeader
        )
        const responseMessage = await dbApi.getReplayData(
          event.data.payload.initAttempt ?? 1,
          event.data.payload.features
        )
        responseMessage['sizeOfDownload'] = bytesDownloaded || -1

        // first single attempt data result
        //  with attempt options info:
        worker.postMessage({
          type: 'REPLAY_DATA_READY',
          payload: responseMessage,
        } as ReplayDataReady)

        // then send postponed attempt data results:
        for (const attNum of responseMessage.postponed) {
          const attemptData = await dbApi.getFormattedAttempt(
            attNum,
            event.data.payload.features
          )
          worker.postMessage({
            type: 'REPLAY_DATA_POSTPONED_ATTEMPT_READY',
            payload: { attNum, attemptData },
          } as ReplayDataPostponedReady)
        }
      } catch (err) {
        worker.postMessage({
          type: 'REPLAY_DATA_ERROR',
          payload: {
            error: err,
          },
        } as ReplayDataError)
      }
      break
    }

    case 'GET_NETWORK_RESPONSE': {
      const { attNum, reqId } = event.data.payload
      const payload = await dbApi.getNetworkDetails(attNum, reqId)

      worker.postMessage({
        type: 'NETWORK_RESPONSE_READY',
        payload,
      } as NetworkResponseReady)
      break
    }
    case 'GET_NETWORK_REQUEST': {
      const { attNum, reqId } = event.data.payload
      const payload = await dbApi.getNetworkRequestDetails(attNum, reqId)
      worker.postMessage({
        type: 'NETWORK_REQUEST_READY',
        payload,
      } as NetworkRequestReady)
      break
    }

    case 'GET_FULL_LOG_MESSAGE': {
      const payload = dbApi.getLogMessage(event.data.payload.eventId)
      worker.postMessage({
        type: 'FULL_LOG_MESSAGE_READY',
        payload,
      } as FullLogMessageReady)
      break
    }

    case 'GET_CONSOLE_PROPS_MESSAGE': {
      const { attNum, logId } = event.data.payload
      const message = await dbApi.getConsolePropsMessage(attNum, logId)
      if (message) {
        worker.postMessage({
          type: 'CONSOLE_PROPS_MESSAGE_READY',
          payload: { message },
        } as ConsolePropsMessageReady)
      }
      break
    }

    default:
      console.warn('Unknown message received in DatabaseWorker.')
      break
  }
})
