import { parse, walk, generate, clone, find } from 'css-tree'
import { assetsCache } from './assetsCache'
import { warnOnce } from './Replay/warnOnce'
import { inlineAsset, getCachedInlineAsset } from './inlineAsset'

import type {
  CssNode,
  StringNode,
  Url,
  List,
  Selector,
  ListItem,
  WalkContext,
} from 'css-tree'
import type { HrefResolver } from './Replay/hrefResolver'
import type { NetworkResources } from './useDomReplay'
import { isDiscoveryReplayUrl } from './isDiscoveryReplayUrl'

const focusSelectors = [':focus-within', ':focus-visible', ':focus']

const updateUrlWithDocument = (
  node: StringNode | Url,
  doc: Document,
  networkResources: NetworkResources,
  hrefResolver: HrefResolver,
  baseUrl: string,
  inlineBlobUrls: boolean
) => {
  const cssUrl = node.value
  // if the url is a data url or a fragment, we don't need to process it
  if (cssUrl.startsWith('data') || cssUrl.startsWith('#')) {
    return
  }

  const resolvedUrl = hrefResolver.resolve(baseUrl, doc)
  const nestedResolvedUrl = hrefResolver.resolveAgainst(
    cssUrl,
    resolvedUrl,
    doc
  )

  if (
    nestedResolvedUrl &&
    // Prevent circular imports
    nestedResolvedUrl !== resolvedUrl
  ) {
    const possibleHash =
      networkResources.domNetworkAssets.pathHash[nestedResolvedUrl]
    if (possibleHash) {
      const hashInCache = getCachedInlineAsset(possibleHash)
      if (hashInCache) {
        node.value = hashInCache
      } else {
        const responseContext =
          networkResources.domNetworkAssets.hashData[possibleHash]

        let processedCss
        if (!responseContext || responseContext?.isBase64) {
          processedCss = inlineAsset({ asset: responseContext, inlineBlobUrls })
        } else {
          processedCss = inlineAsset({
            asset: {
              ...responseContext,
              isBase64: false,
              body: rewriteCss(
                responseContext.body ?? '',
                nestedResolvedUrl,
                testReplayUpdateUrl({
                  doc,
                  networkResources,
                  hrefResolver,
                  inlineBlobUrls: inlineBlobUrls ?? false,
                }),
                possibleHash,
                false
              ),
            },
            inlineBlobUrls,
          })
        }

        node.value = processedCss
      }
    } else if (isDiscoveryReplayUrl(cssUrl)) {
      node.value = cssUrl
    } else {
      warnOnce(
        nestedResolvedUrl,
        `Failed to find response for (${nestedResolvedUrl})`
      )
      const processedCss = 'data:;base64'
      node.value = processedCss
    }
  }
}

const processCssNode = (node: CssNode) => {
  if (
    node.type === 'PseudoClassSelector' &&
    focusSelectors.includes(`:${node.name}`)
  ) {
    // create a new data-cy-replay-focus attribute selector to replace the focus pseudo class selector
    node = {
      type: 'AttributeSelector',
      name: {
        type: 'Identifier',
        name: `data-cy-replay-${node.name}`,
      },
      matcher: null,
      value: null,
      flags: null,
    }
  } else if ('children' in node && node.children) {
    // if the node has children, we need to recursively call this function
    node.children = node.children.map(processCssNode)
  }

  return node
}

const addCypressFocusAttributes = ({
  node,
  item,
  list,
}: {
  node: Selector
  item: ListItem<CssNode>
  list: List<CssNode>
}) => {
  // create a clone of the node so we can modify it
  const cloneNode = processCssNode(clone(node))

  // insert the new data-cy-replay-focus selector before the focus pseudo class selector
  list.insertData(cloneNode, item)
}

const shouldRewrite = (css: string) => {
  // if the css contains an @import or url or a focus selector, we need to rewrite it
  return (
    css.includes('@import') ||
    css.includes('url(') ||
    focusSelectors.some((selector) => css.includes(selector))
  )
}

const hasFocusPseudoClassSelector = (node: CssNode) => {
  return !!find(
    node,
    (node) =>
      node.type === 'PseudoClassSelector' &&
      focusSelectors.includes(`:${node.name}`)
  )
}

export const testReplayUpdateUrl = (args: {
  doc: Document
  networkResources: NetworkResources
  hrefResolver: HrefResolver
  inlineBlobUrls: boolean
}): ((args: { node: StringNode | Url; baseHref: string }) => void) => {
  return ({
    node,
    baseHref,
  }: {
    node: StringNode | Url
    baseHref: string
  }): void => {
    return updateUrlWithDocument(
      node,
      args.doc,
      args.networkResources,
      args.hrefResolver,
      baseHref,
      args.inlineBlobUrls
    )
  }
}

export const rewriteCss = (
  cssText: string | null,
  href: string,
  // Cache key if this stylesheet can be cached
  // Dangerous to use outside of network body hash.
  updateUrl: (args: { node: StringNode | Url; baseHref: string }) => void,
  key?: string,
  isElementAttribute: boolean = false
) => {
  if (!cssText) {
    return ''
  }

  // if for some reason the cssText is not a string, we can't rewrite it,
  // so we just return the cssText as is
  if (typeof cssText !== 'string') {
    return cssText
  }

  const result = key && assetsCache.get(key)
  if (result) {
    return result
  }

  if (!shouldRewrite(cssText)) {
    if (key) {
      assetsCache.set(key, cssText)
    }
    return cssText
  }

  const ast = parse(cssText, {
    context: isElementAttribute ? 'declarationList' : 'stylesheet',
  })

  let selectorBeingProcessed: Selector | null = null
  walk(ast, {
    enter(node: CssNode, item: ListItem<CssNode>, list: List<CssNode>) {
      const self = this as WalkContext

      switch (node.type) {
        case 'Url':
          updateUrl({
            node,
            baseHref: href,
          })
          break
        case 'String':
          if (self.atrule?.name === 'import') {
            updateUrl({
              node,
              baseHref: href,
            })
          }
          break
        case 'Selector':
          // if we find a focus selector, we need to add a new selector to the rule,
          // replacing the focus selectors with our custom cy-replay-focus attribute selectors.
          // we only want to process the top-level selectors, since it's possible to have nested
          // selectors, so we need to keep track of the selector we are processing
          if (!selectorBeingProcessed && hasFocusPseudoClassSelector(node)) {
            selectorBeingProcessed = node
            addCypressFocusAttributes({ node, list, item })
          }
          break
        default:
          break
      }
    },
    leave(node: CssNode) {
      // reset the selectorBeingProcessed after we have processed it
      if (node === selectorBeingProcessed) {
        selectorBeingProcessed = null
      }
    },
  })

  const finalCSS = generate(ast)
  if (key) {
    assetsCache.set(key, finalCSS)
  }
  return finalCSS
}
