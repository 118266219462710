import type * as ProtocolTypes from '@app/capture-protocol/src/types'

export type RootRunnable = ProtocolTypes.RootRunnable

export interface TestAttempt {
  commands: unknown[] // add more descriptive type if needed
  time: number
}

export interface TestModel {
  attempts: TestAttempt[]
}
export type LogProps = ProtocolTypes.CommandLog

export type runnablesStoreType = {
  reset(): void
  setRunnables(rootRunnable: ProtocolTypes.RootRunnable): void
  addLog(log: ProtocolTypes.CommandLog): void
  updateLog(log: ProtocolTypes.CommandLog): void
  updateTest(
    updates: {
      id: string
      err: ProtocolTypes.TestLifecycleEndEvent['err']
      state: ProtocolTypes.TestLifecycleEndEvent['state']
    },
    cb: VoidFunction
  ): void
  _tests: Record<string, TestModel>
}

// These are intentionally "require"'s rather than imports, otherwise the types
// will be imported, and they're going to be wrong because they're coming from the app.
// We need to redefine the surface-area of what we expect the types to be above
const { Attempt: CypressAppAttempt } = require('./reporter/attempts/attempts')
const {
  default: CypressAppCollapsible,
} = require('./reporter/collapsible/collapsible')
const { default: TestModel } = require('./reporter/test/test-model')
const { default: AttemptModel } = require('./reporter/attempts/attempt-model')
const { default: CommandModel } = require('./reporter/commands/command-model')
const { default: ErrorStack } = require('./reporter/errors/error-stack')
const { Reporter } = require('./reporter/main')

// Removes the number of timers we're dealing with when scrubbing for performance
CommandModel.prototype._checkLongRunning = function () {}

export {
  CypressAppAttempt,
  CypressAppCollapsible,
  TestModel,
  AttemptModel,
  CommandModel,
  ErrorStack,
  Reporter,
}

// We need to reference the singletons created automatically within the
// Cypress App, otherwise we are dealing with a whole separate instance
// of states than the components we are importing here:
const { default: runnablesStore } =
  require('./reporter/runnables/runnables-store') as {
    default: runnablesStoreType
  }
const { default: statsStore } = require('./reporter/header/stats-store')
const { default: appState } = require('./reporter/lib/app-state')
const { default: scroller } = require('./reporter/lib/scroller')
const { default: cypressEvents } = require('./reporter/lib/events')

// We need to init Cypress events on our
// own, and only once per session:
cypressEvents.init({
  runnablesStore,
  appState,
  scroller,
  statsStore,
})

export { cypressEvents, runnablesStore, appState }
