export const findCommonPrefix = (strings: string[]) => {
  if (strings.length === 0) {
    return ''
  }

  let prefix = strings[0] as string

  for (let i = 1; i < strings.length; i++) {
    while (strings[i]?.indexOf(prefix) !== 0) {
      prefix = prefix.substring(0, prefix.length - 1)

      if (prefix === '') {
        return ''
      }
    }
  }

  return prefix
}
