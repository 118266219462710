import type { ImagesAndStylesAssets } from '../webworkers/database.worker'
import { Base64 } from 'js-base64'

type Base64String = string
export const toBase64Asset = (
  asset?: ImagesAndStylesAssets['hashData']['']
): Base64String => {
  if (!asset) {
    return ''
  }
  return `data:${asset.mimeType};base64,${
    asset.isBase64 ? asset.body : Base64.encode(asset.body!)
  }`
}
