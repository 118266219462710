export interface GetAttemptCommandsQueryResult {
  id: number
  type: string
  runnable_id: string
  attempt: number
  timestamp: number
  payload: string
  has_console_props: number
}

export const getAttemptCommandsQuery = (schemaVersion: number = 0) => {
  const hasConsolePropsSupport = schemaVersion >= 2
  return `
SELECT 
  e.id,
  e.type,
  e.runnable_id,
  e.attempt,
  e.timestamp,
  ${
    hasConsolePropsSupport
      ? `CASE
    WHEN COUNT(console_props.command_log_id) > 0 THEN 1
    ELSE 0
  END AS has_console_props`
      : '0 as has_console_props'
  },

  -- IMPORTANT: Some fields can be many MBs of data.
  -- We only need to display the beginning of the text.
  -- We need to guard the ux from getting those data dumps.
  -- Add more cases if needed:
  json_group_object(
    p.key,
    CASE
      -- clips root "message" field:
      WHEN p.key = 'message' AND LENGTH(p.value) > 1000 THEN 
        SUBSTR(p.value, 1, 1000) || '...'

      -- clips "err.message" field:
      WHEN p.key = 'err' AND LENGTH(JSON_EXTRACT(p.value, '$.message')) > 1000 THEN 
        JSON_SET(p.value, '$.message', SUBSTR(JSON_EXTRACT(p.value, '$.message'), 1, 1000) || '...')
        
      ELSE p.value
    END
  ) AS payload

FROM 
  events AS e,  
  json_each(e.payload) AS p
${
  hasConsolePropsSupport
    ? `LEFT JOIN console_props ON console_props.command_log_id = json_extract(payload, '$.id')`
    : ''
}
WHERE e.runnable_id = ?
AND e.attempt = ?
AND e.type IN ('log:added', 'log:changed', 'test:after:run', 'test:after:run:async')
AND (json_extract ( e.payload, '$.hidden' ) IS NULL OR json_extract ( e.payload, '$.hidden' ) = 0)
AND p.key IN (
  'alias',
  'aliasType',
  'coords',
  'displayName',
  'event',
  'highlightAttr',
  'hookId',
  'id',
  'instrument',
  'isStubbed',
  'message',
  'method',
  'name',
  'numElements',
  'numResponses',
  'referencesAlias',
  'snapshots',
  'state',
  'testId',
  'timeout',
  'type',
  'url',
  'visible',
  'wallClockStartedAt',
  'err',
  'callCount',
  'functionName',
  'sessionInfo',
  'renderProps'

  -- INTENTIONALLY IGNORED:
  -- 'testCurrentRetry'
  --    this field prevents attempt changes
  --    from working as expected when we use the OpenSource component:
  -- 'hidden'
  --    this field means nothing to the UI
)
GROUP BY e.id
`
}
