import type {
  ConsoleEvent,
  CypressAppEvent,
} from '../webworkers/database.worker'
import { findCommonPrefix } from './findCommonPrefix'
import { getTextSize } from '../utils/getTextSize'
import type {
  ConsoleApiCalled,
  ExceptionThrown,
} from '@app/capture-protocol/src/db/schemas/v1/payload-types'

export class ConsoleItemFactory {
  private getConsoleValue(
    p?: Pick<
      NonNullable<
        ConsoleApiCalled['args'][number]['preview']
      >['properties'][number],
      'type' | 'subtype' | 'value'
    >,
    isPreview = false
  ) {
    if (!p) {
      return ''
    }
    if (p.type === 'object') {
      if (p.subtype === 'null') {
        return 'null'
      }
      if (p.subtype === 'array') {
        return p.value
      }
      return '{...}'
    }
    if (p.type === 'number') {
      return Number(p.value)
    }
    if (p.type === 'boolean') {
      return p.value
    }
    if (p.type === 'undefined') {
      return 'undefined'
    }
    return isPreview ? `'${p.value}'` : (p.value ?? '')
  }

  private objectPreview(
    arg: ConsoleApiCalled['args'][number],
    separator = ':'
  ) {
    if (!arg.preview) {
      return arg.description ?? ''
    }
    const properties = arg.preview.properties.map((p) => {
      return `${p.name}${separator} ${this.getConsoleValue(p, true)}`
    })
    return `${
      arg.preview?.description !== 'Object'
        ? `${arg.preview?.description} `
        : ''
    }{${properties.join(', ')}}`
  }

  private typedArrayPreview(arg: ConsoleApiCalled['args'][number]) {
    if (!arg.preview) {
      return arg.description ?? ''
    }
    const properties = arg.preview.properties.map((p) => {
      if (!Number.isNaN(Number(p.name))) {
        return this.getConsoleValue(p, true)
      }
      return `${p.name}: ${this.getConsoleValue(p, true)}`
    })
    return `${arg.preview?.description} [${properties.join(', ')}]`
  }

  private arrayPreview(arg: ConsoleApiCalled['args'][number]) {
    if (!arg.preview) {
      return arg.description ?? ''
    }
    const properties = arg.preview.properties.map((p) => {
      return this.getConsoleValue(p, true)
    })
    return `${arg.preview?.description} [${properties.join(', ')}]`
  }

  private statePreview(arg: ConsoleApiCalled['args'][number]) {
    if (!arg.preview) {
      return arg.description ?? ''
    }
    const currentState = arg.preview.properties.find(
      (p) => p.name === '[[PromiseState]]' || p.name === '[[GeneratorState]]'
    )!
    const endResult = arg.preview.properties.find(
      (p) => p.name === '[[PromiseResult]]'
    )!
    return `${arg.preview?.description} {<${this.getConsoleValue(
      currentState
    )}>${endResult ? `: ${this.getConsoleValue(endResult)}` : ''}}`
  }

  private entryPreview(arg: ConsoleApiCalled['args'][number]): string {
    if (!arg.preview?.entries) {
      return arg.description ?? ''
    }
    const properties = arg.preview.entries.map((e) => {
      const key = e.key ? this.buildArgumentPreview(e.key, true) : undefined
      return `${
        key ? `${this.buildArgumentPreview(e.key!, true)} -> ` : ''
      }${this.buildArgumentPreview(e.value, true)}`
    })
    return `${arg.preview?.description} {${properties.join(', ')}}`
  }

  private buildArgumentPreview(
    arg: ConsoleApiCalled['args'][number],
    isPreview = false
  ): string {
    switch (arg.type) {
      case 'object':
        switch (arg.subtype) {
          case 'date':
            return arg.description ?? ''
          case 'null':
            return 'null'
          case 'typedarray':
            return this.typedArrayPreview(arg)
          case 'array':
            return this.arrayPreview(arg)
          case 'promise':
          case 'generator':
            return this.statePreview(arg)
          case 'map':
          case 'weakmap':
          case 'set':
          case 'weakset':
            return this.entryPreview(arg)
          case 'error':
          case 'arraybuffer':
          case 'dataview':
          case 'regexp':
          case 'webassemblymemory':
          case 'wasmvalue':
            return arg.preview?.description ?? ''
          case 'node':
            return `<${arg.preview?.description}></${arg.preview?.description}>`
          case 'iterator':
            // Not sure how to trigger this one
            return arg.description ?? ''
          case 'proxy':
          default:
            // Standard object + Classname
            return this.objectPreview(arg)
        }
      case 'undefined':
        return 'undefined'
      case 'number':
      case 'boolean':
        return (
          (arg.value
            ? this.getConsoleValue(arg)?.toString()
            : arg.description) ?? ''
        )

      case 'string':
        // eslint-disable-next-line no-case-declarations
        const v = arg.value ? this.getConsoleValue(arg) : arg.description
        return (isPreview ? `'${v}'` : v?.toString()) ?? ''
      case 'function':
      case 'symbol':
      case 'bigint':
        return arg.description ?? ''
      default:
        return ''
    }
  }

  private getCallFrames(
    type: ConsoleApiCalled['type'],
    stackTrace:
      | ConsoleApiCalled['stackTrace']
      | ExceptionThrown['exceptionDetails']['stackTrace']
  ) {
    // TODO: how do we safely determine what logs
    // have stackFrames that are worth parsing:
    const shouldParse = ['warning', 'error', 'assert'].includes(type)
    if (!shouldParse || !stackTrace) {
      return []
    }

    const paths = stackTrace.callFrames.map((callFrames) => callFrames.url)
    const commonPrefix = findCommonPrefix(paths)
    return stackTrace.callFrames.map((callFrames) => {
      const functionName = callFrames.functionName
        ? callFrames.functionName
        : '(anonymous)'
      const url = callFrames.url.replace(commonPrefix, '')
      const lineNumber = callFrames.lineNumber + 1
      return {
        functionName,
        path: `${url}:${lineNumber}`,
      }
    })
  }

  private handleMessageSize(message: string, clipText = true) {
    // measure before you clip:
    const { kilobytes, megabytes } = getTextSize(message)
    const originalSize = kilobytes < 1000 ? `${kilobytes}kB` : `${megabytes}MB`

    // then clip if necessary:
    const clipped = clipText && message.length > 5000
    if (clipped) message = `${message.slice(0, 5000)}...`

    return {
      originalSize,
      clipped,
      message,
    }
  }

  public getConsoleMessage(args: ConsoleApiCalled['args'], clipText = true) {
    let format: 'italic' | null = null
    let ignoreNext: number = 0
    const message = args
      .map((v, idx) => {
        if (ignoreNext > 0) {
          ignoreNext--
          return ''
        }

        if (v.type === 'object') {
          format = 'italic'
        }
        if (
          typeof v.value === 'string' &&
          v.value.indexOf('%c') >= 0 &&
          idx === 0 &&
          args.length > 0
        ) {
          const parts = v.value.split('%c')
          v.value = parts.join('')
          ignoreNext = parts.length - 1
        }
        return this.buildArgumentPreview(v)
      })
      .join(' ')

    const sanitizedMsg = this.handleMessageSize(message, clipText)
    return {
      ...sanitizedMsg,
      format,
    }
  }

  private exceptionParser({
    id: eventId,
    payload,
  }: {
    id: string | number
    payload: ExceptionThrown
  }) {
    const { exceptionDetails, timestamp } = payload
    const { stackTrace, text, exception, executionContextId } = exceptionDetails
    const callFrames = this.getCallFrames('error', stackTrace)
    const message = exception?.description
      ? `${text} ${exception?.description}`
      : text
    const sanitizedMsg = this.handleMessageSize(message)
    return {
      id: eventId,
      eventId,
      compareKey: `${message}-${executionContextId}`,
      format: null,
      variant: 'error',
      callFrames,
      eventStart: timestamp,
      eventEnd: timestamp,
      summaryCount: 1,
      ...sanitizedMsg,
      // message used for search in the ui.
      // avoid having to do this more than once:
      lowerCaseMessage: message.toLowerCase(),
    } as const as ConsoleEvent
  }

  private normalParser({
    id: eventId,
    payload,
  }: {
    id: number
    payload: ConsoleApiCalled
  }) {
    const { args, type, stackTrace, timestamp } = payload
    const { format, message, clipped, originalSize } =
      this.getConsoleMessage(args)
    const callFrames = this.getCallFrames(type, stackTrace)
    const variant = type === 'log' ? 'verbose' : type
    return {
      id: eventId.toString(),
      eventId,
      compareKey: `${message}-${variant}`,
      variant,
      message,
      summaryCount: 1,
      eventStart: timestamp,
      eventEnd: timestamp,
      callFrames,
      format,
      clipped,
      originalSize,
      // message used for search in the ui.
      // avoid having to do this more than once:
      lowerCaseMessage: message.toLowerCase(),
    } as const as ConsoleEvent
  }

  private tableParser({
    id: eventId,
    payload,
  }: {
    id: number
    payload: ConsoleApiCalled
  }) {
    const { args, type, stackTrace, timestamp } = payload
    const tableArrayArgs = args[0]
    const buildTable = (args: ConsoleApiCalled['args'][number]) => {
      const tableRows: {
        index: string
        [k: string]: string | number | undefined
      }[] = []
      args.preview?.properties.forEach((property) => {
        const properties: {
          index: string
          [k: string]: string | number | undefined
        } = {
          index: property.name,
        }
        // Array based table
        if (args.type === 'object' && args.subtype === 'array') {
          if (property.type !== 'object') {
            properties.value = this.getConsoleValue(property, true)
          } else if (property.valuePreview) {
            property.valuePreview.properties.forEach((p) => {
              properties[p.name] = this.getConsoleValue(p, true)
            })
          }
          // Object based table
        } else if (args.type === 'object') {
          if (property.valuePreview) {
            property.valuePreview.properties.forEach((p) => {
              properties[p.name] = this.getConsoleValue(p, true)
            })
          } else {
            properties.value = this.getConsoleValue(property, true)
          }
        }
        tableRows.push(properties)
      })
      return tableRows
    }

    const table = tableArrayArgs ? buildTable(tableArrayArgs) : 0
    const { message, clipped, originalSize } = this.handleMessageSize(
      tableArrayArgs?.description ?? '',
      false
    )
    const callFrames = this.getCallFrames(type, stackTrace)
    const variant = type === 'log' ? 'verbose' : type
    const id = `${eventId}-${variant}`

    return {
      id,
      eventId,
      compareKey: eventId.toString(),
      variant,
      message,
      summaryCount: 1,
      table,
      eventStart: timestamp,
      eventEnd: timestamp,
      callFrames,
      format: null,
      clipped,
      originalSize,
      // message used for search in the ui.
      // avoid having to do this more than once:
      lowerCaseMessage: message.toLowerCase(),
    } as const as ConsoleEvent
  }

  public makeItem(item: any): ConsoleEvent {
    if (item.type === 'runtime:exception-thrown') {
      return this.exceptionParser(item)
    }
    if (
      item.payload.type === 'table' &&
      item.payload.args[0].type === 'object'
    ) {
      return this.tableParser(item)
    }
    return this.normalParser(item)
  }
}

export const formatConsoleEvents = (
  events: CypressAppEvent<ConsoleApiCalled | ExceptionThrown>[]
) => {
  const results: ConsoleEvent[] = []
  let nextItem: ConsoleEvent

  events.forEach((item, idx) => {
    const factory = new ConsoleItemFactory()
    const currItem: ConsoleEvent = factory.makeItem(item)

    if (currItem.compareKey === nextItem?.compareKey) {
      // if currId & nextId are the same, we assume we are dealing with
      // multiple instances of the same log, so we "summarize them"
      // into a single event with a counter and updated timestamps:
      nextItem.eventStart = Math.min(nextItem.eventStart, currItem.eventStart)
      nextItem.eventEnd = Math.max(nextItem.eventEnd, currItem.eventEnd)
      nextItem.summaryCount++
    } else {
      // if currId & nextItem.id are NOT the same,
      // then we finalize the item:
      if (nextItem) {
        results.push(nextItem)
      }

      // and create a new item to build up
      // in the next iterations:
      nextItem = currItem
    }

    // if this is the last item in the array, we must
    // ensure it gets added to the results list:
    if (idx === events.length - 1) {
      results.push(nextItem)
    }
  })

  // ensure they are in the correct order on the way out.
  return results.sort((a, b) => a.eventStart - b.eventStart)
}
