import React, { useMemo } from 'react'
import type { EventsPayload } from '@app/capture-protocol/src/db/schemas/v1/payload-types'
import { formatTestDuration } from '../../../../../utils/formatTestDuration'
import {
  IconWarningCircle,
  IconObjectPinModern,
} from '@cypress-design/react-icon'
import type { ConsoleDrillShape } from '../ConsolePanel'
import { LargeDownload } from '../../LargeDownload/LargeDownload'
import {
  DevtoolListItem,
  DevtoolListItemTestingProps,
} from '../../DevtoolListItem/DevtoolListItem'
import { Timeline, WaterfallPill } from '../../WaterfallPill/WaterfallPill'
import styles from './ConsoleLogListItem.module.scss'
import cs from 'clsx'

// for testing purposes:
export const CONSOLE_GHOST_MARKER = 'tr-ghost-console-leading'

// Icon not yet available the design system:
const WarningTriangle: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0C4.38665 0 2.92854 0.961474 2.29302 2.44437L0.282111 7.13648C0.0959771 7.5708 0 8.03839 0 8.51091C0 10.4379 1.56212 12 3.48909 12H6H8.51091C10.4379 12 12 10.4379 12 8.51091C12 8.03839 11.904 7.5708 11.7179 7.13648L9.70698 2.44437C9.07146 0.961474 7.61335 0 6 0ZM3.48909 10C2.66669 10 2 9.33331 2 8.51091C2 8.30924 2.04096 8.10968 2.1204 7.92432L4.13131 3.23221C4.45168 2.48468 5.18671 2 6 2C6.81329 2 7.54832 2.48468 7.86869 3.23221L9.8796 7.92432C9.95904 8.10968 10 8.30924 10 8.51091C10 9.33331 9.33331 10 8.51091 10H6H3.48909ZM7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4V5C5 5.55228 5.44772 6 6 6C6.55228 6 7 5.55228 7 5V4ZM6 9C6.55228 9 7 8.55229 7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55229 5.44772 9 6 9Z"
      fill="#EDBB4A"
    />
  </svg>
)

const GHOST_COLOR = '#9095AD'
const STYLING_MAP = {
  verbose: {
    color: '#D0D2E0',
    Icon: IconWarningCircle,
  },
  info: {
    color: '#D0D2E0',
    Icon: IconWarningCircle,
  },
  warning: {
    color: '#EDBB4A',
    Icon: WarningTriangle,
  },
  error: {
    color: '#E45770',
    Icon: IconWarningCircle,
  },
  focused: {
    color: '#6470F3',
    Icon: (props: React.ComponentProps<typeof IconObjectPinModern>) => (
      <IconObjectPinModern
        {...props}
        data-cy="console-pinned-icon"
        strokeColor="purple-400"
        fillColor="purple-700"
        height={16}
        width={16}
      />
    ),
  },
  assert: {
    color: '#E45770',
    Icon: IconWarningCircle,
  },
}

export const LogCount: React.FC<{
  variant: LogVariant
  count: number
  isGhost?: boolean
}> = ({ variant, count, isGhost }) => {
  return (
    <div
      data-cy="console-log-count"
      className={cs(
        styles['logCount'],
        isGhost
          ? styles['logCount-ghost']
          : variant === 'error'
            ? styles['logCount-error']
            : variant === 'warning'
              ? styles['logCount-warning']
              : ''
      )}
    >
      {count}
    </div>
  )
}

type LogVariant = EventsPayload['cdp']['log:added']['entry']['level']
export type ConsoleLogListItemProps = {
  isGhost?: boolean
  timeline: Timeline
  message: string | React.ReactNode
  variant: LogVariant
  collapsible?: React.ReactNode
  table?: { index: string; [k: string]: string }[]
  focused?: boolean
  onFocus: () => void
  summaryCount?: number
  format: 'italic' | null
  style?: React.CSSProperties // required for react-virtualized
  drill: ConsoleDrillShape
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  clipped: boolean
  originalSize: string
  eventId: string | number
  handleDownloadMessage: (eventId: string | number) => void
  topSeparator?: boolean
  onMouseEnter?: VoidFunction
  onMouseLeave?: VoidFunction
  snapshotsUpdate?: VoidFunction
  snapshotsReset?: VoidFunction
} & DevtoolListItemTestingProps

const getTableHeaders = (table?: ConsoleLogListItemProps['table']) => {
  if (!table) {
    return
  }
  const headers = new Set<string>()
  table.forEach((tr) => {
    Object.keys(tr).forEach((key) => {
      if (key !== 'index') {
        headers.add(key)
      }
    })
  })
  return Array.from(headers)
}

export const ConsoleLogListItem: React.FC<ConsoleLogListItemProps> = ({
  isGhost,
  timeline,
  message,
  variant,
  collapsible,
  focused,
  onFocus,
  summaryCount,
  table,
  format,
  style,
  drill,
  expanded,
  setExpanded,
  clipped,
  originalSize,
  eventId,
  handleDownloadMessage,
  topSeparator,
  onMouseEnter,
  onMouseLeave,
  snapshotsUpdate,
  snapshotsReset,
  ...rest
}) => {
  const styleKey = focused ? 'focused' : variant
  const { color: _color, Icon } =
    STYLING_MAP[styleKey] ?? STYLING_MAP['verbose']
  const color = isGhost ? GHOST_COLOR : _color

  const tableHeaders = useMemo(() => getTableHeaders(table), [table])

  return (
    <DevtoolListItem
      {...rest}
      index={drill.index}
      data-cy={`devtool-console-item-${drill.index}`}
      data-pendo="replay-devtool-console-item"
      drill={drill}
      style={style}
      topSeparator={topSeparator}
      persistBackgroundOnExpanded
      focusColor={color}
      pinned={focused}
      onClickContainer={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      snapshotsUpdate={snapshotsUpdate}
      snapshotsReset={snapshotsReset}
      expanded={expanded}
      setExpanded={setExpanded}
      leading={
        <div
          data-cy="console-item-leading"
          className={cs(
            styles['leadingContainer'],
            isGhost && CONSOLE_GHOST_MARKER
          )}
        >
          <div className={styles['waterfallPillContainer']}>
            <WaterfallPill
              data-cy="log-waterfall"
              color={color}
              isRange={!!timeline.eventEnd}
              timeline={timeline}
              tooltipContent={
                summaryCount ? (
                  <div>
                    Logged between:{' '}
                    {formatTestDuration(timeline.eventStart - timeline.min)} and{' '}
                    {formatTestDuration(timeline.eventEnd! - timeline.min)}
                  </div>
                ) : (
                  <div>
                    Logged at:{' '}
                    {formatTestDuration(timeline.eventStart - timeline.min)}
                  </div>
                )
              }
            />
          </div>
          <div
            className={cs(
              styles['icon'],
              // flips exclamation upside down for an "i":
              (variant === 'verbose' || variant === 'info') &&
                !focused &&
                styles['icon-info'],
              isGhost && styles['icon-ghost']
            )}
          >
            <Icon
              size="16"
              color={color}
              data-cy={`console-${styleKey}-icon`}
              viewBox="0 0 16 16"
              height={12}
              width={12}
            />
          </div>
        </div>
      }
      collapsibleContent={collapsible}
    >
      <div data-cy="devtool-console-item-content">
        {table && tableHeaders ? (
          <div className={cs(styles['tableContainer'])}>
            <table>
              <thead>
                <tr>
                  <th>(index)</th>
                  {tableHeaders.map((header) => {
                    return <th key={header}>{header}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {table.map((tr) => (
                  <tr key={tr.index}>
                    <td>{tr.index}</td>
                    {tableHeaders.map((th) => (
                      <td key={`${tr.index}_${th}`}>{tr[th]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className={styles['message']}>
          <div
            data-cy="console-item-message"
            className={cs(
              format === 'italic' && styles['italicMessage'],
              isGhost && styles['ghost']
            )}
          >
            {message}
          </div>
          {summaryCount && (
            <LogCount
              count={summaryCount}
              variant={variant}
              isGhost={isGhost}
            />
          )}
        </div>
        {clipped && (
          <LargeDownload
            className="mt-[10px] mb-[10px]"
            message="Sorry, we can't show logs this big."
            data-cy="console-item-message-download"
            size={originalSize}
            onClick={(e) => {
              e.stopPropagation()
              handleDownloadMessage(eventId)
            }}
          />
        )}
      </div>
    </DevtoolListItem>
  )
}
