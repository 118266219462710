.customIcon {
  display: inline-block;
  vertical-align: text-bottom;
}

.spin {
  animation: icon-spin 2s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9jeXByZXNzLWlvL2N5cHJlc3Mtc2VydmljZXMvZnJvbnRlbmQvcGFja2FnZXMvZGVzaWduLXN5c3RlbS9zcmMvY29tcG9uZW50cy9jdXN0b20taWNvbnMiLCJzb3VyY2VzIjpbIm1vZHVsZS5DdXN0b21JY29ucy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTtJQUNFOztFQUVGO0lBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuY3VzdG9tSWNvbiB7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgdmVydGljYWwtYWxpZ246IHRleHQtYm90dG9tO1xufVxuXG4uc3BpbiB7XG4gIGFuaW1hdGlvbjogaWNvbi1zcGluIDJzIGluZmluaXRlIGxpbmVhcjtcbn1cblxuQGtleWZyYW1lcyBpY29uLXNwaW4ge1xuICAwJSB7XG4gICAgdHJhbnNmb3JtOiByb3RhdGUoMGRlZyk7XG4gIH1cbiAgMTAwJSB7XG4gICAgdHJhbnNmb3JtOiByb3RhdGUoMzYwZGVnKTtcbiAgfVxufVxuIl19 */