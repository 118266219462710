.contentLoader {
  animation: shimmer 4s linear infinite;
  background-size: 200% 200%;
}

@keyframes shimmer {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9jeXByZXNzLWlvL2N5cHJlc3Mtc2VydmljZXMvZnJvbnRlbmQvcGFja2FnZXMvZGFzaGJvYXJkL3NyYy9jb21tb24vbG9hZGVyIiwic291cmNlcyI6WyJtb2R1bGUuQ29udGVudExvYWRlci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtJQUNFOztFQUVGO0lBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuY29udGVudExvYWRlciB7XG4gIGFuaW1hdGlvbjogc2hpbW1lciA0cyBsaW5lYXIgaW5maW5pdGU7XG4gIGJhY2tncm91bmQtc2l6ZTogMjAwJSAyMDAlO1xufVxuXG5Aa2V5ZnJhbWVzIHNoaW1tZXIge1xuICBmcm9tIHtcbiAgICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiAyMDAlIDA7XG4gIH1cbiAgdG8ge1xuICAgIGJhY2tncm91bmQtcG9zaXRpb246IC0yMDAlIDA7XG4gIH1cbn1cbiJdfQ== */