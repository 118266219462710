import type { ImagesAndStylesAssets } from '../webworkers/database.worker'
import { toBase64Asset } from './toBase64Asset'
import { Base64 } from 'js-base64'

const assetCache: Map<string, string> = new Map()

const inDiscovery = typeof window !== 'undefined' && window.IN_DISCOVERY

export const getCachedInlineAsset = (assetHash: string): string | undefined => {
  return assetCache.get(assetHash)
}

export const clearInlineAssetCache = () => {
  for (const url of Object.values(assetCache)) {
    URL.revokeObjectURL(url)
  }
  assetCache.clear()
}

export const inlineAsset = ({
  asset,
  inlineBlobUrls = false,
}: {
  asset: ImagesAndStylesAssets['hashData'][''] | undefined
  inlineBlobUrls: Boolean | undefined
}): string => {
  if (!asset) {
    return ''
  }

  let inlinedAsset = assetCache.get(asset.hash)

  if (inlinedAsset) {
    return inlinedAsset
  }

  const shouldUseBlobs = !inDiscovery && inlineBlobUrls

  if (shouldUseBlobs && asset.body) {
    // if the asset was already converted to base 64 convert it back.
    const body = asset.isBase64 ? Base64.toUint8Array(asset.body) : asset.body
    const blob = new Blob([body], {
      type: asset.mimeType,
    })

    inlinedAsset = URL.createObjectURL(blob)
  } else if (asset.proxyUrl) {
    inlinedAsset = asset.proxyUrl
  } else {
    inlinedAsset = toBase64Asset(asset)
  }
  assetCache.set(asset.hash, inlinedAsset)

  return inlinedAsset
}
